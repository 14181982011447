.footer{
    background-color: rgb(0, 0, 0);
    color: white;
    background: url("../media/images/bg-2.jpg");
    background: url("../media/images/bg-22.png");
    background-position: center ;
    background-size: cover;
}
.footer>div{
    background-color: rgba(0, 0, 0, 0.581);
}

.footer-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
padding: 70px 0 40px;
    
}

.footer-col-1{
    text-align: center;
}
.footer-col-1-logo{
    font-size: 2.5rem;
    font-weight: 600;
    animation: anim-popoutin 3s ease infinite;
    
}

.footer-col-1-text{
    max-width: 420px;
    margin-top: 10px;
}


.footer-col-2-col-heading{
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.footer-col-2-col-points{
    display: grid;
    gap: 10px;
    max-width: 350px;
}
.footer-col-2-col-points span{
    min-width: 30px;
    
}
.footer-col-2-col-points>div{
    display: flex; 
    align-items: center;
    gap: 10px;
    
}
.footer-col-2-col-points>div>span{
    display: flex; align-items: center;
    justify-content: center;
    height: 30px;
    width:  30px;
    font-size: 1.2rem;
}

.footer-cc{
    color: white;
    text-align: center;
    padding: 10px;
    border-top: solid 1px rgb(79, 79, 79);
}

@media only screen and (max-width: 800px) {
    
    
    .footer-content{
        display: flex;
        gap: 50px;
    padding: 40px 30px 40px;
    flex-direction: column;
        
    }
    
    .footer-col-1-logo{
        font-size: 2rem;
        font-weight: 600;
        animation: anim-popoutin 3s ease infinite;
        
    }

    
.footer-col-1-text{
    font-size: .9rem;
}

    
    
    .footer-col-2-col-heading{
        font-size: 1.3rem;
        margin-bottom: 10px;
        font-weight: 600;
    }
    
    .footer-col-2-col-points{
        display: grid;
        gap: 10px;
    }
    .footer-col-2-col-points>div{
        display: flex; align-items: center;
        gap: 10px;
        
    }
    .footer-col-2-col-points>div>span{
        display: flex; align-items: center;
        justify-content: center;
        height: 30px;
        width:  30px;
        font-size: 1.2rem;
    }
    
    .footer-cc{
        font-size: .9rem;
    }
    
    
    
    
}

