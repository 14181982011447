.keywords{
    max-width: 1350px;
    margin: 40px auto;
}

.keywords-head{
    display: flex;
    justify-content: space-between;
    font-size: 2.2rem;
    font-weight: 700;
    align-items: center;
}
.keywords-content{
    margin:20px auto;
    text-align: justify;
}

@media only screen and (max-width: 800px) {
    .keywords{
        margin: 25px 15px;
    }
    
    .keywords-head{
        font-size: 1.3rem;
    }
    .keywords-head-icon{
        font-size: 1.8rem;
    }
    .keywords-content{
        font-size: .9rem;
    }
}