.header{
    background-color: black;
    color: white;
    background: url("../media/images/bg-5.jpg");
    background-size: cover;
}
.header>div{
    background-color: rgba(0, 0, 0, 0.655);
}
.header>div>div{
    max-width: 1400px;
    margin: auto;
}


.header-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

.header-nav-logo{
    font-size: 2rem;
    font-weight: 600;
    margin-left: 30px;
    animation: anim-popoutin 3s ease infinite;
}


.header-nav-logo>img{
    width: 70px;
    padding: 20px 0;
}
.header-nav a{
    color: white;
    text-decoration: none;
}
.header-nav a:hover{
    text-decoration: underline;
}

.header-nav-menu{
    display: flex;
    gap: 50px;
}

.header-nav-menu>span>a{
    border: solid 2px #ffaa2e;
    color: #ffaa2e;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 1.1rem;
}



/* ------------------------------------------------- */

.header-main{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1300px;
    margin: auto;
    padding: 50px 0 0px;

}
.header-main-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-main-col-2{
display: flex;
align-items: center;
}
.header-main-col-2 img{
    width: 100%;
}
.header-main-heading-1{
    /* text-align: center; */
    font-size: 2.2rem;
    font-weight: 600;
    animation: flip 4s ease ;
}
.header-main-heading-1 span{
    color: #ffaa2e;
}
.header-main-points{
    /* padding: 100px; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
    flex-wrap: wrap;
    padding: 20px 0;
}

.header-main-points>div{
    display: flex;
    align-items: center;
    gap: 15px;
}

.header-main-points-txt-1{
    animation: flip 4s ease;
    animation-delay: 2s;
}

.header-main-points-txt-3{
    animation: flip 4s ease;
    animation-delay: 4s;
}

.header-main-points-txt-5{
    animation: flip 4s ease;
    animation-delay: 6s;
}

.header-main-points-txt-7{
    animation: flip 4s ease;
    animation-delay: 8s;
}

.header-main-points-txt-9{
    animation: flip 4s ease;
    animation-delay: 10s;
}

.header-main-points-txt-11{
    animation: flip 4s ease;
    animation-delay: 12s;
}

.header-main-points-txt-2{
    animation: flip 4s ease;
    animation-delay: 14s;
}

.header-main-points-txt-4{
    animation: flip 4s ease;
    animation-delay: 16s;
}

.header-main-points-txt-6{
    animation: flip 4s ease;
    animation-delay: 18s;
}

.header-main-points-txt-8{
    animation: flip 4s ease;
    animation-delay: 20s;
}

.header-main-points-txt-10{
    animation: flip 4s ease;
    animation-delay: 22s;
}

.header-main-points-txt-12{
    animation: flip 4s ease;
    animation-delay: 24s;
}

.header-main-points span{
    font-size: 1rem;
    color: #ffaa2e;
}

.header-main-heading-2{
    /* text-align: center; */
    font-size: 1.8rem;
    font-weight: 500;
}
.header-main-heading-2 span{
    color: #ffaa2e;
}

.header-button {
    display: flex;
    justify-content: center;
    gap: 100px;
    padding:  40px 0 100px;
}
.header-button a{
    /* color: #ffaa2e; */
    background-color: #ffaa2e;
    /* color: white; */
    color: black;
    border: solid 2px #ffaa2e;
    padding: 10px 70px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 30px;
    text-decoration: none;
}



@keyframes flip {
    

0% {
    opacity: 0;
    transform: rotateX(90def);
}
30% {
    opacity: 1;
    transform: rotateX(1turn);
}
100% {
    opacity: 1;
    transform: rotateX(1turn);
}
    
}


@media only screen and (max-width: 800px) {

    .header{
        background-color: black;
        color: white;
        background: url("../media/images/bg-5.jpg");
        background-size: cover;
    }
    .header>div{
        background-color: rgba(0, 0, 0, 0.655);
        padding: 0 25px;
    }
    
    .header-nav-logo{
        font-size: 1.5rem;
        margin-left: 5px;

    }
    
    .header-nav-logo>img{
        width: 55px;
        padding: 0px 0;

    }

    .header-nav a:hover{
        text-decoration: underline;
    }
    
    
    .header-nav-menu>span>a{
        font-size: 1rem;
        padding: 5px 15px;
    }
    
    
    
    /* ------------------------------------------------- */
    
    .header-main{
        grid-template-columns: 1fr ;
        padding: 30px 0 0px;
    
    }
    .header-main-col{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .header-main-col-2{
    display: flex;
    align-items: center;
    }
    .header-main-col-2 img{
        display: none;
    }
    .header-main-heading-1{
        /* text-align: center; */
        font-size: 1.25rem;
        font-weight: 600;
        /* animation: flip 8s ease infinite; */
    }
    .header-main-heading-1 span{
        color: #ffaa2e;
    }
    .header-main-points{
        /* padding: 100px; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px ;
        flex-wrap: wrap;
    }
    
    
    .header-main-points span{
        color: #ffaa2e;
    }

    
.header-main-points-txt-1{
    animation: flip 4s ease;
    animation-delay: 2s;
}

.header-main-points-txt-2{
    animation: flip 4s ease;
    animation-delay: 4s;
}

.header-main-points-txt-3{
    animation: flip 4s ease;
    animation-delay: 6s;
}

.header-main-points-txt-4{
    animation: flip 4s ease;
    animation-delay: 8s;
}

.header-main-points-txt-5{
    animation: flip 4s ease;
    animation-delay: 10s;
}

.header-main-points-txt-6{
    animation: flip 4s ease;
    animation-delay: 12s;
}

.header-main-points-txt-7{
    animation: flip 4s ease;
    animation-delay: 14s;
}

.header-main-points-txt-8{
    animation: flip 4s ease;
    animation-delay: 16s;
}

.header-main-points-txt-9{
    animation: flip 4s ease;
    animation-delay: 18s;
}

.header-main-points-txt-10{
    animation: flip 4s ease;
    animation-delay: 20s;
}

.header-main-points-txt-11{
    animation: flip 4s ease;
    animation-delay: 22s;
}

.header-main-points-txt-12{
    animation: flip 4s ease;
    animation-delay: 24s;
}

    
    .header-main-heading-2{
        font-size: 1.2rem;
        font-weight: 500;
    }
    .header-main-heading-2 span{
        color: #ffaa2e;
    }
    
    .header-button {
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
        padding:  40px 0 ;
    }
    .header-button a{
        padding: 5px 30px;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 30px;
        text-decoration: none;
    }
    
    
}