.how-we{
background-color: #faf4eb;
padding: 70px 0 100px;

}
.how-we>div{
  max-width: 1350px;
  margin: 0px auto;
}

.how-we-heading {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 70px;
}

.how-we-heading > span {
  color: #ffaa2e;
}

.how-we-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.how-we-content-col {
  box-shadow: 4px 4px 6px 0 #ffffffb5, -4px -4px 6px 0 #ffffff9a,
    inset -4px -4px 6px 0 #82828233,
    inset 4px 4px 6px 2px hsla(0, 0%, 67%, 0.201);
  border-radius: 25px;
  padding: 20px;
  text-align: center;
  background-color: white;
}

.how-we-content-col-icon {
  width:  150px;
  height: 150px;
  /* border: 1px solid; */
  margin: auto;
  /* box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36,
  inset -4px -4px 6px 0 #3e3e3e33,
  inset 4px 4px 6px 2px hsla(0, 0%, 43%, 0.201);

  box-shadow: 4px 4px 6px 0 #ffffffb5, -4px -4px 6px 0 #ffffff9a,
  inset -4px -4px 6px 0 #82828233,
  inset 4px 4px 6px 2px hsla(0, 0%, 67%, 0.201); */
  
  border-radius: 50%;
  margin-top: -80px;
  margin-bottom: 0px;
  /* background-color: white; */
}
.how-we-content-col-icon >img{
  width: 100%;
}

.how-we-content-col-heading {
  font-weight: 600;
  font-size: 1.1rem;
  /* color: #ffaa2e; */
}
.how-we-content-col-text {
  font-size: 0.9rem;
}

@media only screen and (max-width: 800px) {
  .how-we{
    background-color: #faf4eb;
    padding: 40px 20px 60px;
    
    }
    .how-we>div{
      max-width: 1350px;
      margin: 0px auto;
    }
    

  .how-we-heading {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .how-we-heading > span {
    color: #ffaa2e;
  }

  .how-we-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: auto;
  }

  .how-we-content-col {
    
    border-radius: 20px;
    padding:  15px;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .how-we-content-col-icon {
    width: 80px;
    height: 80px;
    margin: auto;
    
    border-radius: 50%;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: white;
  }

  .how-we-content-col-content{
    flex: 1;
  }
  
  .how-we-content-col-heading {
    font-weight: 600;
    font-size: 1.1rem;
    /* color: #ffaa2e; */
  }
  .how-we-content-col-text {
    font-size: 0.85rem;
  }
}
