.we-served{
    margin: 100px auto;
    background: url("../media/images/bg-2.jpg");
    background: url("../media/images/bg-3.jpg");
    background-position: center;
    background-size: cover;
    color: white;
    
}
.we-served>div{
    background-color: rgba(255, 255, 255, 0.451);
    background-color: rgba(0, 0, 0, 0.756);
    padding: 100px;
}
.we-served>div>div{
    max-width: 1400px;
    margin: auto;

}

.we-served-heading{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
}
.we-served-heading>span{
    color: #ffaa2e;
}

.we-served-content-points-col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 50px 0 0px 120px;
}
.we-served-content-point{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 1.1rem;
}
.we-served-content-point>span{
    color: #ffaa2e;
    font-size: 1.1rem;
    font-weight: 1000;
}

@media only screen and (max-width: 800px) {
    .we-served{
    background-position: bottom right;
        
        margin: 50px 0px;
        
    }
    
    .we-served>div{
        padding: 30px 20px;
    }
    
    .we-served-heading{
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .we-served-heading>span{
        color: #ffaa2e;
    }
    
    .we-served-content-points-col{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 10px 0 ;
    }
    .we-served-content-point{
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 1rem;
    }
    .we-served-content-point>span{
        color: #ffaa2e;
        font-size: 1rem;
        font-weight: 1000;
    }
    
    
}