.why-us{
    max-width: 1300px;
    margin: 100px auto; 
       
}

.why-us-heading{
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}

.why-us-heading>span{
    color: #ffaa2e;
}

.why-us-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.why-us-content-col-1{
    background-color: rgb(209, 209, 209);
    background-color: #fff3e1;
    background: url("../media/images/1-bg.jpg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    
}
.why-us-content-col-2{
    display: grid;
    gap: 20px;
}

.why-us-content-col-2-point{
    display: flex;
    align-items: center;
    gap: 10px;
}

.why-us-content-col-2-point-icon>img{
    width: 25px;
}
.why-us-content-col-2-point-heading{
    font-size: 1rem;
    font-weight: 600;
}

@media only screen and (max-width: 800px) {
    .why-us{
        margin: 50px 20px;    
    }
    
    .why-us-heading{
        font-size: 1.6rem;
        margin-bottom: 10px;
    }

    .why-us-heading>span{
        color: #ffaa2e;
    }
    
    
    .why-us-content{
        grid-template-columns: 1fr ;
        gap: 10px;
    }
    
    .why-us-content-col-1{
        height: 250px;
        background-size: 90%;
    }
    .why-us-content-col-2{
        gap: 15px;
    }
    
    
    
    
    
}