.client-say{
    max-width: 1300px;
    margin: 100px auto;
}
.client-say-heading{
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
}
.client-say-heading>span{
    color: #ffaa2e;
}
.client-say-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin: 40px 0;
}
.client-say-col-img{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-say-col-img>img{
    width: 200px;
    /* height: 50px; */
}
.client-say-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.client-say-col-rating{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 5px;
}
.client-say-col-rating>span{
    color: #ffaa2e;
    font-size: 1.5rem;
}

.client-say-col-link a{
    /* color: #ffaa2e; */
    font-weight: 500;
    font-size: 1.2rem;
    
}

@media only screen and (max-width: 800px) {
    .client-say{
        margin: 50px auto;
    }
    .client-say-heading{
        font-size: 1.7rem;
    }
    .client-say-heading>span{
        color: #ffaa2e;
    }
    .client-say-content{
        grid-template-columns: repeat(1, 1fr);
        margin: 20px 0;
        gap: 40px;
    }
    .client-say-col-img{
        height: auto;
    }
    
    .client-say-col-img>img{
        width: 120px;
        /* height: 50px; */
    }
    .client-say-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }
    .client-say-col-rating{
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .client-say-col-rating>span{
        color: #ffaa2e;
        font-size: 1.4rem;
    }
    
    .client-say-col-link a{
        /* color: #ffaa2e; */
        font-weight: 500;
        font-size: 1rem;
        
    }
    
    
    
}

