.num-inc{
    max-width: 1300px;
    margin: -60px auto 0;
}
.num-inc>div{
    /* background-color: rgba(58, 58, 58, 0.471); */
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    padding: 20px 5px 30px;
}

.num-inc-col-heading{
    font-size: 2.2rem;
    font-weight: 700;
    color: #ffaa2e;
}
.num-inc-col-text{
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(69, 69, 69);
}
.num-inc-col-text>span{
    display: none;
}


@media only screen and (max-width: 800px) {
    .num-inc{
        margin: 0px auto ;
    }
    .num-inc>div{
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    }
    
    .num-inc-col-heading{
        font-size: 1.6rem;
        font-weight: 700;
        color: #ffaa2e;
    }
    .num-inc-col-text{
        font-size: .9rem;
        line-height: .6rem;
        /* margin-top: 10px; */
    }

    .num-inc-col-text>span{
        display: block;
    }
        
    
    
    
}

